"use client";

import { useEffect, useState } from "react";
import Text from "../Text";
import Icon from "../Icon";
import { withUIContext } from "@/context";

const CLOSE_TIMEOUT = 5000;


const NotificationItem = ({
  id,
  type = "success",
  title,
  closeNotification,
}) => {
  const [isClosing, setIsClosing] = useState(false);
  let timer;
  const close = () => {
    setIsClosing(true);
    setTimeout(() => {
      closeNotification(id);
    }, 300);
  };
  useEffect(() => {
    timer = setTimeout(close, CLOSE_TIMEOUT);
    return () => {
      clearTimeout(timer);
    };
  }, []);


  return (
    <div
      key={id}
      className={
        `notification ${type} ${isClosing ? 'isClosing' : ''}`
      }
    >
      <div className={'iconWrapper'}>
        <Icon name={'info'} />
      </div>
      <Text className={'title font-avenir-regular'} text={title}/>
      <Icon name='close' onClick={close} className={'close'}/>
    </div>
  );
};

export default withUIContext(NotificationItem, ['closeNotification'])