import { memo, useMemo } from 'react';
import { usePathname } from 'next/navigation';

//* Style
import BurgerMenuStyle from './style';

//* HOC's
import { withUIContext } from '@/context';

//* Config
import { config } from '@/helpers';

//* Components
import Text from '@/components/global/Text';
import CustomLink from '@/components/global/CustomLink';
import Button from '@/components/global/Button';
import PopupSubscribePlan from '@/components/global/PopupSubscribePlan';

const BurgerMenu = memo(({ isBurgerMenuOpen, headerMenus, url, openPopup }) => {
	const pathname = usePathname();

	const storeMenus = useMemo(() => {
		return headerMenus.map((item, index) => {
			return (
				<CustomLink
					className={`burger-menu-link ${pathname.includes(item.path) ? 'active' : ''}`}
					key={index}
					url={item.path}>
					<Text
						className={'h3 font-argent royal-blue-1000 uppercase'}
						text={item.name}
					/>
				</CustomLink>
			);
		});
	}, [headerMenus]);

	return (
		<BurgerMenuStyle className={`burger-menu ${isBurgerMenuOpen ? 'active' : ''}`}>
			<div className='menus-wrap'>
				{storeMenus}
				<CustomLink
					className={`burger-menu-link contact ${pathname.includes(config.routes.contacts.path) ? 'active' : ''}`}
					url={config.routes.contacts.path}>
					<Text
						className={'h3 font-argent royal-blue-1000 uppercase'}
						text={'contacts'}
					/>
				</CustomLink>
				<Button
					external
					url={'https://personal-account.owner.one/en/signin'}
					text='CLIENTS ONLY'
					className={'join-wait-list primary'}
				/>
			</div>
		</BurgerMenuStyle>
	);
});

export default withUIContext(BurgerMenu, ['openPopup']);
