import styled from 'styled-components';

const PopupSubscribePlanStyle = styled.div`
	--titleMarginBottom: var(--sp7x);
	--subscribeButtonMarginTop: var(--sp7x);
	--successMessageMarginTop: var(--sp3x);

	.subscribe-button {
		display: flex;
		margin-top: var(--subscribeButtonMarginTop);
		margin-left: auto;
		margin-right: auto;
	}

	.ant-form-item {
		margin-top: var(--gutter);
	}

	.subscribe-plan-title {
		text-align: center;
        margin-bottom: var(--titleMarginBottom);
	}

	.subscribe-plan-description {
		text-align: center;
	}

	.success-message {
		text-align: center;
		margin-top: var(--successMessageMarginTop);
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--titleMarginBottom: var(--sp5x);
		--subscribeButtonMarginTop: var(--sp5x);
		--successMessageMarginTop: var(--sp2-5x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--titleMarginBottom: var(--sp5x);
		--subscribeButtonMarginTop: var(--sp5x);
		--successMessageMarginTop: var(--sp2-5x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--titleMarginBottom: var(--sp5x);
		--subscribeButtonMarginTop: var(--sp5x);
		--successMessageMarginTop: var(--sp2-5x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--titleMarginBottom: var(--sp4x);
		--subscribeButtonMarginTop: var(--sp4x);
		--successMessageMarginTop: var(--sp1-5x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--titleMarginBottom: var(--sp4x);
		--subscribeButtonMarginTop: var(--sp4x);
		--successMessageMarginTop: var(--sp1-5x);
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--titleMarginBottom: var(--sp4x);
		--subscribeButtonMarginTop: var(--sp4x);
		--successMessageMarginTop: var(--sp1-5x);
	}
`;

export default PopupSubscribePlanStyle;
