'use client';

import { useRef, useEffect } from 'react';
import { usePathname, useParams } from 'next/navigation';
import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { ScrollSmoother } from 'gsap/ScrollSmoother';

//* HOC's
import { withUIContext } from '@/context';

// gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

function ScrollWrapper({ children, isBurgerMenuOpen, popupIsOpen }) {
	//! Refs
	const main = useRef();
	const smoother = useRef();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	const wrapperStyle = {
		
	  };

	//! Scroll Top In Router Change
	// useEffect(() => {
	// 	gsap.to(smoother.current, {
	// 		scrollTop: 0,
	// 		duration: 0,
	// 	});
	// }, [pathname, params.lang]);

	// useGSAP(
	// 	() => {
	// 		smoother.current = ScrollSmoother.create({
	// 			smooth: 0.01,
    //             speed: 1,
    //             ease: 'power3.out'
	// 		});
	// 	},
	// 	{ scope: main }
	// );

	// useEffect(() => {
	// 	smoother.current.paused(isBurgerMenuOpen || popupIsOpen, true);
	// }, [isBurgerMenuOpen, popupIsOpen]);

	return (
		<div
			ref={main}
			id='smooth-wrapper' style={wrapperStyle}>
			<div id='smooth-content'>{children}</div>
		</div>
	);
}

export default withUIContext(ScrollWrapper, ['isBurgerMenuOpen', 'popupIsOpen']);
