'use client';
import { memo, useMemo, useEffect, useRef, useCallback,useState } from 'react';
import { usePathname, useRouter, useParams } from 'next/navigation';
import gsap from 'gsap';

//* HOC's
import { withUIContext, withDataContext } from '@/context';

//* Style
import HeaderStyle from './style';

//* Config
import config from '@/helpers/_config';

//* Components
import Text from '@/components/global/Text';
import BurgerMenu from './BurgerMenu';
import CustomLink from '@/components/global/CustomLink';
import Button from '@/components/global/Button';
import LanguageSwitcher from './LanguageSwitcher';
import Image from '@/components/global/Image';
import Icon from '@/components/global/Icon';
import PopupSubscribePlan from '@/components/global/PopupSubscribePlan';

const AppHeader = memo(({ winWidth, toggleBurgerMenu, isBurgerMenuOpen, closeBurgerMenu, getGlobalData, openPopup }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Next Navigation
	const pathname = usePathname();
	const router = useRouter();
	const params = useParams();
	//! Refs
	const logoRef = useRef();
	const listItemsRef = useRef();
	const contactsWrapRef = useRef();
	const [hash,updateHash] = useState();

	//! Checks
	const headerMenus = useMemo(() => Object.values(config.routes).filter((item) => item.isHeader));
	const darkHeaders = useMemo(() => Object.values(config.routes).filter((item) => item.darkHeader));
	const isDark = useMemo(() => !!darkHeaders.find((item) => pathname.includes(item.path)), [darkHeaders, pathname]);

	//! Store Header Menu
	const storeHeaderMenus = useMemo(() => {
		return headerMenus.map((item, index) => {
			return (
				<CustomLink
					className={`navigation-link ${pathname.includes(item.path) ? 'active' : ''}`}
					key={index}
					url={item.path}>
					<Text
						className={'p p6 font-avenir-regular royal-blue-1000 uppercase text'}
						text={item.name}
					/>
				</CustomLink>
			);
		});
	}, [headerMenus]);

	//! Handle Logo Click
	const handleLogoClick = useCallback(() => {
		closeBurgerMenu();

		if (pathname !== '/') {
			router.push('/');
		} else {
			window.scrollTo({ top: 0 });
		}
	}, [pathname]);

	useEffect(() => {
		closeBurgerMenu();    
		updateHash(window.location.hash);
	}, [winWidth, pathname,params]);

	useEffect(() => {
		gsap.to(logoRef.current, { top: '50%' });
		listItemsRef.current && gsap.to(listItemsRef.current, { opacity: 1, delay: 0.5 });
		contactsWrapRef.current && gsap.to(contactsWrapRef.current, { opacity: 1, delay: 0.5 });
	}, [winWidth]);

	return (
		<>
			<BurgerMenu
				headerMenus={headerMenus}
				isBurgerMenuOpen={isBurgerMenuOpen}
				url={globalData.join_wait_list_url}
			/>

			<HeaderStyle className={`header ${isBurgerMenuOpen ? 'burger-open' : ''} ${isDark ? 'dark' : ''}`}>
				<div
					className='menus-wrap'
					ref={listItemsRef}>
					{/* <CustomLink
						className={`navigation-link ${pathname == ('/') && !hash ? 'active' : ''}`}
						
						url='/'>
						<Text
							className={'p p6 font-avenir-regular royal-blue-1000 uppercase text'}
							text='HOME'
						/>
					</CustomLink>
					<CustomLink url="/#for_whom" className={`navigation-link ${hash == ('#for_whom') ? 'active' : ''}`}>
						<Text
							className={'p p6 font-avenir-regular royal-blue-1000 uppercase text'}
							text='FOR WHOM'
						/>
					</CustomLink> */}
					{storeHeaderMenus}
				</div>
				<div
					ref={logoRef}
					onClick={handleLogoClick}
					className={`logo-link ${winWidth < 1280 ? 'mobile' : ''}`}>
					<Image
						priority
						className={'logo'}
						src={isDark && !isBurgerMenuOpen ?  '/images/logo-white.svg' : '/images/logo.svg'}
					/>
				</div>
				<div
					className='contacts-wrap'
					ref={contactsWrapRef}>
					<div className='buttons-wrap'>
						{/* <CustomLink
							className={`contact-us ${pathname.includes(config.routes.contacts.path) ? 'active' : ''}`}
							url={config.routes.contacts.path}>
							<Text
								className={'p p6 royal-blue-1000 font-avenir-regular uppercase text'}
								text={config.routes.contacts.name}
							/>
						</CustomLink> */}
						<Button
							external
							url={'https://personal-account.owner.one/en/signin'}
							text=' CLIENTS ONLY'
							className={'join-wait-list primary'}
							// onClick={() => openPopup(<PopupSubscribePlan />)}
						/>
                    </div>
                    {/* //! ALSO WHEN UNCOMMENT LANGUAGE SWITCHER FIX BUTTON MARGIN */}
					{/* <LanguageSwitcher /> */}
					<div
						className='burger-wrap'
						onClick={() => toggleBurgerMenu()}>
						<Icon
							className={'icon'}
							name={isBurgerMenuOpen ? 'close' : 'menu'}
						/>
					</div>
				</div>
			</HeaderStyle>
		</>
	);
});

export default withDataContext(withUIContext(AppHeader, ['winWidth', 'toggleBurgerMenu', 'isBurgerMenuOpen', 'closeBurgerMenu', 'openPopup']), ['getGlobalData']);
