import styled from "styled-components";

export default styled.div`
  position: fixed;
  top: 96px;
  right: 0;
  max-width: 515px;
  width: calc(100vw - 16px);
  height: calc(100vh - 96px);
  overflow: hidden;
  z-index: 20;
  padding-left: 52px;
  transition: transform .5s;

  ${(props) =>
    !props.$isOpen ? `
      transform: translateX(calc(100% - 48px));
    ` 
    : ''}

  .widget_header {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 14px 20px;
    justify-content: space-between;
    border-bottom: 1px solid #dcdde4;
    border-radius: 12px 0 0 0;
  }

  .widget_header .font-argent {
    font-size: 18px;
    font-weight: 600;
  }

  .widget_header .font-avenir-regular {
    font-size: 14px;
    font-weight: 700;
    color: var(--sky200);
  }

  .widget_content {
    padding: 12px 16px;
    overflow: auto;
    background: var(--light200);
    border-bottom-left-radius: 12px;
    height: calc(100vh - 96px - 56px);
  }

  .widget_label {
    position: absolute;
    left: 1px;
    top: calc(50% + 43px);
    background: #D97560;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 48px;
    max-height: 48px;
    padding: 12px 60px;
    border-radius: 8px 8px 0 0;
    transform: rotate(-90deg) translateX(50%) translateY(-92px);
    cursor: pointer;
    user-select: none;
    flex-direction: row-reverse;
    border: 2px solid #fff;
  }

  .widget_label .icon {
    height: 24px;
    width: 24px;
    font-size: 24px;
    color: #fff;
    transform: rotate(-180deg);
    transition: transform .25s;
  }

  .widget_label .font-argent {
    font-size: 18px;
    font-weight: 600;
  }

  .widget_label.isOpen .icon{
    transform: rotate(0deg);
  }

  @media only screen and (max-width: ${(props) =>
      props.theme.mediaQuery.tabletSizeSMin}) {
    height: calc(100vh - 56px);
    top: 56px;
    padding-left: 42px;
    max-width: 510px;

    ${(props) =>
    !props.$isOpen ? `
      transform: translateX(calc(100% - 42px));
    `
    : ''}

    .widget_content {
      height: calc(100vh - 56px - 56px);
    }

    .widget_label {
      top: 50%;
      max-height: 32px;
      height: 32px;
      padding: 8px 12px;
      transform: rotate(-90deg) translateX(50%) translateY(-38px);
    }

    .widget_label .icon {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }

    .widget_label .font-argent {
      font-size: 16px;
    }
  }
`;
