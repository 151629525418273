"use client";

import { withUIContext } from "@/context";
import NotificationItem from "./notification-item";
import NotificationStyle from "./styles";

export default withUIContext(({ notificationsList }) => {
  return (
    <NotificationStyle>
      {notificationsList.map((item) => (
        <NotificationItem
          id={item.id}
          type={item.type}
          title={item.title}
          key={item.id}
        />
      ))}
    </NotificationStyle>
  );
}, ['notificationsList']);
