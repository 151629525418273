'use client';
import { memo as Memo, useEffect, useMemo, useCallback, useState } from 'react';

//* HOC's
import withUIContext from '@/context/consumerHOC/UIConsumer';

//* Component's
import Icon from '@/components/global/Icon';

//* Style
import PopupStyle from './style';
import Text from '../Text';

const Popup = Memo(({ closePopup, popupIsOpen, popupComponent, popupProps }) => {
	const components = useMemo(() => ({}), []);
	const Child = useMemo(() => typeof popupComponent === 'string' && components[popupComponent] && components[popupComponent], [popupComponent]);
	const [fixKb, setFixKb] = useState(false);

	useEffect(() => {
		window.addEventListener('keydown', handleKeydown);

		if ('virtualKeyboard' in navigator) {
			navigator.virtualKeyboard.overlaysContent = true;
		}

		return () => {
			window.removeEventListener('keydown', handleKeydown);
		};
	}, []);

	const handleKeydown = useCallback((e) => {
		if (e.key === 'Escape') {
			closePopup();
		}
	}, []);	

  useEffect(() => {
    const fn = () => {
			setFixKb(window.visualViewport.height < window.innerHeight);
    }
		window.visualViewport.addEventListener("resize", fn);

    return () => {
      window.visualViewport.removeEventListener("resize", fn);
		}
  }, [])

	return popupIsOpen ? (
		<PopupStyle 
			className={`
				${popupProps.className || ''} 
				${popupProps.paStyle ? 'popup-paStyle' : ''}
			`}
		>
			<div
				className={`popup-background ${popupProps.hideBackdrop ? 'hide' : ''}`}
				onClick={closePopup}
			/>

			<div className={`popup-content-container`}>
				<div 
					className={`
						popup-content-wrap 
						${popupProps.position ? 'fixed' : ''}
						${fixKb ? 'fixKb' : ''}
					`}
					style={popupProps.position && {
						'--posX': `${popupProps.position[0]}px`,
						'--posY': `${popupProps.position[1]}px`,
					}}
				>	
					{ popupProps.paStyle 
					? <div className={'popup-header'}>
				<Text className={'font-argent'}>{popupProps.title}</Text>
						<Icon name={`close`} onClick={closePopup}/>
					</div>
					: <div
							className={'close-wrap'}
							onClick={closePopup}
						>
							<Icon name={`close`} />
						</div>
					}

					{typeof popupComponent === 'string' ? <Child /> : popupComponent}
				</div>
			</div>
		</PopupStyle>
	) : null;
});

export default withUIContext(Popup, ['closePopup', 'popupIsOpen', 'popupComponent', 'popupProps']);
