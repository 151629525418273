'use client';

import { useMemo, useRef, useState } from 'react';
import { usePathname, useParams } from 'next/navigation';
import { Power2, gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';

//* HOC's
import { withDataContext, withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config, useActiveSection } from '@/helpers';

//* Components
import Text from '../Text';
import Image from '../Image';
import Button from '../Button';
import CustomLink from '../CustomLink';
import FormItem from '../Form/FormItem';
import FormInput from '../Form/FormInput';
import FormContainer from '../Form/FormContainer';
import { useSearchParams } from 'next/navigation'

//* Style
import FooterStyle from './style';

gsap.registerPlugin(ScrollTrigger);

const AppFooter = ({ translate, getGlobalData, winWidth, subscribe }) => {
	//! Global Data
	const globalData = getGlobalData();

	//! Next Navigation
	const pathname = usePathname();
	const params = useParams();

	//! Refs
	const containerRef = useRef([]);
	const containerRef2 = useRef([]);
	// const footerTitleRef = useRef([]);
	const ref1 = useRef([]);
	const ref2 = useRef([]);
	const ref3 = useRef([]);
	const ref4 = useRef([]);
	const ref5 = useRef([]);
	const reservedRef = useRef([]);

	//! State
	const [isSuccess, setIsSuccess] = useState(false);

	//! Active Sections
	const activeSection = useActiveSection(containerRef, 100);
	const activeSection2 = useActiveSection(containerRef2, 100);

	const searchParams = useSearchParams()
	const search = searchParams.toString()

	//! Default Values --- Start ---
	// useGSAP(() => {
	// 	gsap.set(footerTitleRef.current, { scale: winWidth >= 768 ? 1.2 : 1.1, duration: 0, opacity: 0, delay: 0 });
	// }, [pathname, params.lang, winWidth]);

	useGSAP(() => {
		gsap.set(ref1.current, { y: '130%', stagger: 0, duration: 0, delay: 0 });
		gsap.set(ref2.current, { y: '130%', stagger: 0, duration: 0, delay: 0 });
		gsap.set(ref3.current, { y: '130%', stagger: 0, duration: 0, delay: 0 });
		gsap.set(ref4.current, { y: '130%', stagger: 0, duration: 0, delay: 0 });

		if (ref5.current !== undefined || ref5.current !== null) {
			gsap.set(ref5.current, { y: '60%', stagger: 0, duration: 0, delay: 0 });
		}

		gsap.set(reservedRef.current, { y: '300%', duration: 0, delay: 0 });
	}, [pathname, params.lang, winWidth]);
	//! --- End ---

	//! Footer Animation --- Start ---
	// useGSAP(() => {
	// 	if (activeSection) {
	// 		gsap.to(footerTitleRef.current, { scale: 1, duration: 0.8, opacity: 1, delay: 0.1, ease: Power2.easeOut });
	// 	} else {
	// 		gsap.killTweensOf(footerTitleRef.current);
	// 	}
	// }, [activeSection, pathname, params.lang, winWidth]);

	useGSAP(() => {
		if (activeSection2) {
			gsap.to(ref1.current, { y: 0, stagger: 0.1, duration: 1, delay: 0.3, autoRound: false, ease: Power2.easeOut });
			gsap.to(ref2.current, { y: 0, stagger: 0.1, duration: 1, delay: 0.3, autoRound: false, ease: Power2.easeOut });
			gsap.to(ref3.current, { y: 0, stagger: 0.1, duration: 1, delay: 0.3, autoRound: false, ease: Power2.easeOut });
			gsap.to(ref4.current, { y: 0, stagger: 0.1, duration: 1, delay: 0.3, autoRound: false, ease: Power2.easeOut });

			if (ref5.current !== undefined || ref5.current !== null) {
				gsap.to(ref5.current, { y: 0, stagger: 0.1, duration: 1, delay: 0.3, autoRound: false, ease: Power2.easeOut });
			}

			gsap.to(reservedRef.current, { y: 0, duration: 1, delay: 0.5, autoRound: false, ease: Power2.easeOut });
		} else {
			gsap.killTweensOf([...ref1.current, ...ref2.current, ...ref3.current, ...ref4.current, ref5.current, reservedRef.current]);
		}
	}, [activeSection2, pathname, params.lang, winWidth]);
	//! --- End ---

	//! Footer Menu Items Col 1
	const footerMenuItemsCol1 = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].isFooter === 'col-1' && route[1].path != '/blog' ) {
				return (
					<Text
						key={ind}
						ref={(ref) => ref && (ref1.current[ind] = ref)}
						className={`p p6 white-1000 font-avenir-regular ft-col-item ft-col-1-item`}>
						<CustomLink
							url={route[1].path}
							className={`real`}
							content={translate(route[1].name)}
						/>
					</Text>
				);
			}
		});
	}, []);

	//! Footer Menu Items Col 2
	const footerMenuItemsCol2 = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].isFooter === 'col-2') {
				return (
					<Text
						key={ind}
						ref={(ref) => ref && (ref2.current[ind] = ref)}
						className={`p p6 white-1000 font-avenir-regular ft-col-item ft-col-2-item`}>
						<CustomLink
							url={route[1].path}
							className={`real`}
							content={translate(route[1].name)}
						/>
					</Text>
				);
			}
		});
	}, []);

	//! Footer Menu Items Col 3
	const footerMenuItemsCol3 = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].isFooter === 'col-3') {
				return (
					<Text
						key={ind}
						ref={(ref) => ref && (ref3.current[ind] = ref)}
						className={`p p6 white-1000 font-avenir-regular ft-col-item ft-col-3-item`}>
						<CustomLink
							url={route[1].path}
							className={`real`}
							content={translate(route[1].name)}
						/>
					</Text>
				);
			}
		});
	}, []);

	//! Footer Menu Items Col 4
	const footerMenuItemsCol4 = useMemo(() => {
		return Object.entries(config.routes).map((route, ind) => {
			if (route[1].isFooter === 'col-4') {
				return (
					<Text
						key={ind}
						ref={(ref) => ref && (ref4.current[ind] = ref)}
						className={`p p6 white-1000 font-avenir-regular ft-col-item ft-col-4-item`}>
						<CustomLink
							url={route[1].path}
							className={`real`}
							content={translate(route[1].name)}
						/>
					</Text>
				);
			}
		});
	}, []);

	//! Footer Menu Items Col 5
	const footerMenuItemsCol5 = useMemo(() => {
		return globalData?.socials.length > 0
			? globalData.socials.map((item, index) => {
					return (
						<div
							key={index}
							className={`ft-icon-wrapper`}>
							<CustomLink
								external
								url={item.url}
								ariaLabel={item.icon.alt}>
								<Image
									src={item.icon.src}
									alt={item.icon.alt}
								/>
							</CustomLink>
						</div>
					);
			  })
			: null;
	}, [globalData]);

	//! Handle Finish
	const handleFinish = (values) => {
		if (!isSuccess) {

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
			 'event': 'email',
			});

			return subscribe({ email: values.subscribe_email, utm: values.utm }).then(() => {
				setIsSuccess(true);

				setTimeout(() => {
					setIsSuccess(false);
				}, 60000);
			});
		}
	};

	return (
		<FooterStyle
			ref={containerRef}
			className={`footer royal-blue-1000-bg ${pathname.includes('not-found') ? 'disable' : ''}`}>
			<div className={`footer-inner-wrapper`}>
				{/* <div className={`footer-up-wrapper`}>
					<Text
						ref={footerTitleRef}
						className={`h3 white-1000 font-argent capitalize text-center letter-spacing-h3 footer-title`}>
						{globalData.subscription_title}
					</Text>

					<div className={`subscribe-email-wrapper`}>
						<FormContainer onFinish={handleFinish} initialValues={{ ["utm"]: search ? search : "" }} >
							<FormItem
								name={'subscribe_email'}
								validationType={'email'}>
								<FormInput placeholder={translate('writeYouEmail')} />
							</FormItem>
							<FormItem
                    			name={'utm'}
								required={false}>
								<FormInput type="hidden" />
							</FormItem>
							<div className={`subscribe-btn-wrapper`}>
								{isSuccess ? (
									<Text
										className={'p p5 font-avenir-regular success-color'}
										text={'subscribeFooterText'}
									/>
								) : (
									<Button
										type={`submit`}
										className={`primary inverse`}
										text={`submitEmail`}
									/>
								)}
							</div>
						</FormContainer>
					</div>
				</div> */}

				<div
					ref={containerRef2}
					className={`footer-middle-wrapper`}>
					<div className={`ft-md-col ft-md-col-1`}>
						<div className={`ft-md-inner-col`}>
							<Text
								className={`p p1 white-1000 font-argent letter-spacing-p1`}
								text={`product`}
							/>

							<div className={`ft-md-items-wrapper`}>{footerMenuItemsCol1}</div>
						</div>
					</div>

					<div className={`ft-md-col ft-md-col-2`}>
						<div className={`ft-md-inner-col`}>
							<Text
								className={`p p1 white-1000 font-argent letter-spacing-p1`}
								text={`company`}
							/>

							<div className={`ft-md-items-wrapper`}>{footerMenuItemsCol2}</div>
						</div>
					</div>

					<div className={`ft-md-col ft-md-col-3`}>
						<div className={`ft-md-inner-col`}>
							<Text
								className={`p p1 white-1000 font-argent letter-spacing-p1`}
								text={`recourses`}
							/>

							<div className={`ft-md-items-wrapper`}>{footerMenuItemsCol3}</div>
						</div>
					</div>

					<div className={`ft-md-col ft-md-col-4`}>
						<div className={`ft-md-inner-col`}>
							<Text
								className={`p p1 white-1000 font-argent letter-spacing-p1`}
								text={`legal`}
							/>

							<div className={`ft-md-items-wrapper`}>{footerMenuItemsCol4}</div>
						</div>
					</div>

					{globalData?.socials.length > 0 ? (
						<div className={`ft-md-col ft-md-col-5`}>
							<div className={`ft-md-inner-col`}>
								<Text
									className={`p p1 white-1000 font-argent letter-spacing-p1`}
									text={`socialMedia`}
								/>

								<div
									ref={ref5}
									className={`ft-md-items-wrapper ft-md-icons-wrapper`}>
									{footerMenuItemsCol5}
								</div>
								<div
									className={`ft-md-items-wrapper ft-app-links`}>
									<a
										className={`ft-app-link`}
										target="_blank"
										href="https://apps.apple.com/ru/app/owner-one/id6473855308">
										<Image
											width={180}
											height={52}
											className={`contain ft-app-link-image`}
											src={'/images/svg/footer-app-store.svg'}
										/>
									</a>
									<a
										className={`ft-app-link`}
										target="_blank"
										href="https://play.google.com/store/apps/details?id=one.owner.app.android">
										<Image
											width={180}
											height={52}
											className={`contain ft-app-link-image`}
											src={'/images/svg/footer-google-play.svg'}
										/>
									</a>
								</div>
							</div>
						</div>
					) : null}
				</div>

				<div className={`footer-down-wrapper`}>
					<Text
						ref={reservedRef}
						className={`p p6 white-1000 font-avenir-regular reserved-title`}>
						© {new Date().getFullYear()} Owner.One, {translate('allRightsReserved')}
					</Text>
					<div
						className={`ft-payment`}>
						<Image
							width={40}
							height={24}
							className={`contain ft-app-link-image`}
							src={'/images/svg/footer-mc.svg'}
						/>
						<Image
							width={45}
							height={24}
							className={`contain ft-app-link-image`}
							src={'/images/svg/footer-visa.svg'}
						/>
						<Image
							width={40}
							height={24}
							className={`contain ft-app-link-image`}
							src={'/images/svg/footer-unionpay.svg'}
						/>
					</div>
				</div>
			</div>
		</FooterStyle>
	);
};

export default withUIContext(withDataContext(withLanguageContext(AppFooter, ['translate']), ['getGlobalData', 'subscribe']), ['winWidth']);
