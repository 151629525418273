import styled from 'styled-components';

const FooterStyle = styled.footer`
	--ftTopDistance: var(--sp13x);
	--ftBottomDistance: var(--sp7x);
	--ftLeftRightDistance: var(--sp45x);
	--subscribeEmailTopDistance: var(--sp8x);
	--subscribeEmailWidth: var(--sp84x);
	--subscribeBtnTopDistance: var(--sp4x);
	--ftMdColWidth: 20%;
	--ftMdColDistance: var(--sp4x);
	--ftMdItemsTopDistance: var(--sp4x);
	--ftMdIconDistance: var(--sp4x);
	--ftColItemTopDistance: var(--sp2-5x);

	padding: var(--ftTopDistance) var(--ftLeftRightDistance) var(--ftBottomDistance);
	position: relative;
	z-index: 11;

	.footer-up-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.subscribe-email-wrapper {
		width: var(--subscribeEmailWidth);
		margin-top: var(--subscribeEmailTopDistance);
	}

	.subscribe-btn-wrapper {
		display: flex;
		justify-content: center;
		margin-top: var(--subscribeBtnTopDistance);
	}

	.footer-middle-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--ftMdColDistance) / -2);
		margin-top: var(--ftTopDistance);
	}

	.ft-md-col {
		width: var(--ftMdColWidth);
		padding: 0 calc(var(--ftMdColDistance) / 2);
	}

	.ft-md-items-wrapper {
		margin-top: var(--ftMdItemsTopDistance) !important;
	}

	.ft-md-icons-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 calc(var(--ftMdIconDistance) / -2);
	}

	.ft-icon-wrapper {
		width: var(--p1);
		height: var(--p1);
		margin: 0 calc(var(--ftMdIconDistance) / 2);

		.image-cont {
			--proportion: var(--p1);

			img {
				object-fit: contain;
			}
		}

		.link-item {
			display: block;
			transition: transform var(--trTime) ease-out;

			@media (hover: hover) {
				&:hover {
					transform: scale(1);
				}
			}
		}
	}

	.ft-col-item {
		width: fit-content;
		position: relative;

		.link-item {
			display: block;

			&.real {
				position: relative;

				@media (hover: hover) {
					&:hover {
						&::after {
							width: 100%;
						}
					}
				}

				&::after {
					content: '';
					position: absolute;
					bottom: calc(var(--sp1x) * -1);
					left: 0;
					width: 0;
					height: 1px;
					background-color: var(--white1000);
					transition: var(--trTime);
				}
			}
		}

		&:not(:first-child) {
			margin-top: var(--ftColItemTopDistance);
		}

		@media (hover: hover) {
			&:hover {
				.link-item {
					&.real {
					}
				}
			}
		}
	}

	.footer-down-wrapper {
		margin-top: var(--ftTopDistance);
		display: flex
	}

	&.disable {
		display: none;
	}

	.ft-app-link {
		display: block;
		&:not(:first-child) {
			margin-top: 16px
		}
		&-image {
			padding: 0
		}
	}

	.ft-payment {
		display: inline-flex;
		gap: 24px;
		margin-left: 80px
	}

	/* //! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--ftTopDistance: var(--sp10x);
		--ftBottomDistance: var(--sp5x);
		--ftLeftRightDistance: var(--sp35x);
		--subscribeEmailTopDistance: var(--sp6x);
		--subscribeEmailWidth: var(--sp84x);
		--subscribeBtnTopDistance: var(--sp3x);
		--ftMdColWidth: 20%;
		--ftMdColDistance: var(--sp3x);
		--ftMdItemsTopDistance: var(--sp3x);
		--ftColItemTopDistance: var(--sp2x);
		--ftMdIconDistance: var(--sp3x);
	}

	/* //! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--ftTopDistance: var(--sp7x);
		--ftLeftRightDistance: var(--sp11x);
		--ftBottomDistance: var(--sp5x);
		--subscribeEmailTopDistance: var(--sp6x);
		--subscribeEmailWidth: var(--sp84x);
		--subscribeBtnTopDistance: var(--sp3x);
		--ftMdColWidth: 20%;
		--ftMdColDistance: var(--sp3x);
		--ftMdItemsTopDistance: var(--sp3x);
		--ftColItemTopDistance: var(--sp2x);
		--ftMdIconDistance: var(--sp3x);
	}

	/* //! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--ftTopDistance: var(--sp7x);
		--ftLeftRightDistance: var(--sp15x);
		--ftBottomDistance: var(--sp4x);
		--subscribeEmailTopDistance: var(--sp6x);
		--subscribeEmailWidth: var(--sp84x);
		--subscribeBtnTopDistance: var(--sp3x);
		--ftMdColWidth: 20%;
		--ftMdColDistance: var(--sp3x);
		--ftMdItemsTopDistance: var(--sp3x);
		--ftColItemTopDistance: var(--sp2x);
		--ftMdIconDistance: var(--sp3x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--ftTopDistance: var(--sp7x);
		--ftLeftRightDistance: var(--sp10x);
		--ftBottomDistance: var(--sp4x);
		--subscribeEmailTopDistance: var(--sp6x);
		--subscribeEmailWidth: var(--sp84x);
		--subscribeBtnTopDistance: var(--sp3x);
		--ftMdColWidth: 20%;
		--ftMdColDistance: var(--sp2-5x);
		--ftMdItemsTopDistance: var(--sp2-5x);
		--ftColItemTopDistance: var(--sp2x);
		--ftMdIconDistance: var(--sp3x);
	}

	/* //! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--ftTopDistance: var(--sp5x);
		--ftLeftRightDistance: var(--sp5x);
		--ftBottomDistance: var(--sp3x);
		--subscribeEmailTopDistance: var(--sp4x);
		--subscribeEmailWidth: var(--sp84x);
		--subscribeBtnTopDistance: var(--sp3x);
		--ftMdColWidth: 25%;
		--ftMdColDistance: var(--sp2-5x);
		--ftMdItemsTopDistance: var(--sp2-5x);
		--ftColItemTopDistance: var(--sp2x);
		--ftMdIconDistance: var(--sp2-5x);

		.ft-md-col-5 {
			margin-top: var(--sp4x);
		}
		position: static;
	}

	/* //! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--ftTopDistance: var(--sp4x);
		--ftLeftRightDistance: var(--sp2x);
		--ftBottomDistance: var(--sp2x);
		--subscribeEmailTopDistance: var(--sp4x);
		--subscribeEmailWidth: 100%;
		--subscribeBtnTopDistance: var(--sp3x);
		--ftMdColWidth: 50%;
		--ftMdColDistance: var(--sp2-5x);
		--ftMdItemsTopDistance: var(--sp2-5x);
		--ftColItemTopDistance: var(--sp1-5x);
		--ftMdIconDistance: var(--sp3x);

		.ft-md-col-3,
		.ft-md-col-4,
		.ft-md-col-5 {
			margin-top: var(--sp4x);
		}
		.ft-payment {
			margin: 0
		}
		.footer-down-wrapper {
			gap: 16px;
			flex-direction: column-reverse
		}
		position: static;
	}
`;

export default FooterStyle;
