import styled from 'styled-components';

const HeaderStyle = styled.header`
	--headerPadding: var(--sp4x) var(--sp8x);
	--navigationLinkMarginRight: var(--sp6x);
	--contactUsMarginRight: var(--sp6x);
	--logoWidth: var(--sp32x);
	--logoHeight: var(--sp9x);

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: var(--headerPadding);
	z-index: 11;
	background-color: var(--backgroundColor);

	&.dark {
		background-image: linear-gradient(to right, var(--royalBlue1000), var(--royalBlue1000) 50%, var(--backgroundColor) 50%);
		background-size: 200%;
		background-position: 0%;
		transition: background-position 0.3s;

		&.burger-open {
			background-position: 100%;

			.selected-lang-wrap {
				.comp-text {
					color: var(--royalBlue1000);
				}

				.language-dropdown-icon {
					color: var(--royalBlue1000);
				}
			}
		}

		.menus-wrap {
			.navigation-link {
				.comp-text {
					color: var(--white1000);
				}
			}
		}

		.contact-us {
			.comp-text {
				color: var(--white1000);
			}
		}

		.selected-lang-wrap {
			.comp-text {
				color: var(--white1000);
			}

			.language-dropdown-icon {
				color: var(--white1000);
			}
		}
	}

	.menus-wrap {
		display: flex;
		opacity: 0;

		.navigation-link {
			position: relative;
			overflow: hidden;
			margin-right: var(--navigationLinkMarginRight);
			padding-bottom: var(--sp1x);
			margin-top: var(--sp1x);

			.text {
				display: block;
				transition: 0.2s;
			}

			@media (hover: hover) {
				&:hover {
					&::after {
						width: 100%;
					}
				}
			}

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background-color: var(--royalBlue1000);
				transition: var(--trTime);
			}

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: transparent;
			}

			&.active {
				pointer-events: none;

				&::before {
					background-color: var(--royalBlue1000);
				}
			}

			&:last-child {
				margin-right: unset;
			}
		}
	}

	.contacts-wrap {
		display: flex;
		align-items: center;
		opacity: 0;

		.contact-us {
			position: relative;
			overflow: hidden;

			.text {
				padding: var(--sp1x) 0;
				transition: 0.2s;
			}

			@media (hover: hover) {
				&:hover {
					&::after {
						width: 100%;
					}
				}
			}

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background-color: var(--royalBlue1000);
				transition: var(--trTime);
			}

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				background-color: transparent;
			}

			&.active {
				pointer-events: none;

				&::before {
					background-color: var(--royalBlue1000);
				}
			}
		}

		.buttons-wrap {
			display: flex;
			align-items: center;
		}

		.join-wait-list {
			margin: 0 var(--contactUsMarginRight);
			margin-right: unset; //! DELETE THIS LINE WHEN UNCOMMENT LANGUAGE SWITCHER
		}
	}

	.logo-link {
		width: var(--logoWidth);
		position: absolute;
		top: -50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: pointer;

		.logo {
			padding-top: var(--logoHeight);
		}
	}

	.burger-wrap {
		display: flex;
		justify-content: center;
		align-items: center;
		width: var(--sp5x);
		height: var(--sp5x);
		background-color: var(--white1000);
		margin-left: var(--sp2x);
		border-radius: var(--sp0-5x);
		cursor: pointer;

		.icon {
			font-size: var(--sp2-5x);
		}
	}

	.burger-wrap {
		display: none;
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--headerPadding: var(--sp3x) var(--sp6x);
		--navigationLinkMarginRight: var(--sp5x);
		--contactUsMarginRight: var(--sp5x);
		--logoWidth: var(--sp25x);
		--logoHeight: var(--sp7x);
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--headerPadding: var(--sp2-5x) var(--sp5x);
		--navigationLinkMarginRight: var(--sp4x);
		--contactUsMarginRight: var(--sp4x);
		--logoWidth: var(--sp25x);
		--logoHeight: var(--sp7x);
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--headerPadding: var(--sp2x) var(--sp4x);
		--navigationLinkMarginRight: var(--sp3x);
		--contactUsMarginRight: var(--sp3x);
		--logoWidth: var(--sp25x);
		--logoHeight: var(--sp6x);
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--headerPadding: var(--sp2x) var(--sp4x);
		--navigationLinkMarginRight: var(--sp3x);
		--contactUsMarginRight: var(--sp3x);
		--logoWidth: var(--sp25x);
		--logoHeight: var(--sp6x);
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--headerPadding: var(--sp2x) var(--sp5x);
		--logoWidth: var(--sp25x);
		--logoHeight: var(--sp5x);

		justify-content: flex-end;

		.logo-link {
			left: 0;

			&.mobile {
				transform: translate(16px, -50%);
			}
		}

		.menus-wrap,
		.buttons-wrap {
			display: none !important;
		}

		.burger-wrap {
			display: flex;
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--headerPadding: var(--sp1x) var(--sp2x);
		--logoWidth: var(--sp15x);
		--logoHeight: var(--sp3x);

		justify-content: flex-end;

		.logo-link {
			left: 0;

			&.mobile {
				transform: translate(16px, -50%);
			}
		}

		.menus-wrap,
		.buttons-wrap {
			display: none !important;
		}

		.burger-wrap {
			display: flex;
		}
	}
`;

export default HeaderStyle;
