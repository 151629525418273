import { useMemo, useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

//* HOC's
import withLanguageContext from '@/context/consumerHOC/LanguageConsumer';

//* Style
import LanguageSwitcherStyle from './style';

//* Components
import Text from '@/components/global/Text';
import Icon from '@/components/global/Icon';

const LanguageSwitcher = ({ selectedLang, languages }) => {
	//! State
	const [isDropDownActive, setIsDropdownActive] = useState(false);

	const pathname = usePathname();

	const storeLanguages = useMemo(() => {
		return Object.keys(languages).map((lang) => {
			const route = pathname.replace(`/${selectedLang}`, '');

			return (
				selectedLang !== lang && (
					<div
						key={lang}
						className='lang-item font-avenir-regular royal-blue-1000 p p6 uppercase'>
						<Link
							href={`/[lang]${route}`}
							as={`/${lang}${route}`}>
							{languages[lang].params.name}
						</Link>
					</div>
				)
			);
		});
	}, [selectedLang, languages, pathname]);

	//! Check className
	const checkClass = (el, cl) => el.classList.contains(cl) || !!el.closest(`.${cl}`);

	//! Detect click outside
	const handleClickOutside = (e) => {
		if (checkClass(e.target, 'selected-lang-wrap') && !isDropDownActive) {
			setIsDropdownActive(true);
		} else if (!checkClass(e.target, 'dropdown') && isDropDownActive) {
			setIsDropdownActive(false);
		}
	};

	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside);

		return () => {
			window.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isDropDownActive]);

	return (
		<LanguageSwitcherStyle>
			<div className='selected-lang-wrap'>
				<Text
					className={'font-avenir-regular p p6 royal-blue-1000 uppercase'}
					text={selectedLang}
				/>
				<Icon
					className={`language-dropdown-icon ${isDropDownActive ? 'active' : ''}`}
					name={'arrow-down'}
				/>
				{isDropDownActive && <div className={`dropdown`}>{storeLanguages}</div>}
			</div>
		</LanguageSwitcherStyle>
	);
};

export default withLanguageContext(LanguageSwitcher, ['selectedLang', 'languages']);
