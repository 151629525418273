"use client";

import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import CustomLink from "../CustomLink";
import Icon from "../Icon";
import PublicTalkContent from "../PublicTalkContent";
import Text from "../Text";
import PublicTalkWidgetStyle from "./style";

const PublicTalkWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);

  const pathname = usePathname();
  useEffect(() => {
    if (window.innerWidth > 800) {
      setIsOpen(true);
    }
  }, [])

  useEffect(() => {
    setHide(!!pathname.match('/yansnotes/'))
  }, [pathname])

  if (hide) {
    return <></>
  }

  return (
    <PublicTalkWidgetStyle $isOpen={isOpen}>
      <div className={"widget_header"}>
        <Text text={"publicTalk"} className={"font-argent"} />
        <CustomLink url={"/yansnotes"}>
          <Text text={"viewFull"} className={"font-avenir-regular"} />
        </CustomLink>
      </div>
      <div className={"widget_content"}>
        <PublicTalkContent mobileOnly />
      </div>
      <div className={`widget_label ${isOpen ? 'isOpen' : ''}`} onClick={() => setIsOpen(!isOpen)}>
        <Text text={'publicTalk'} className={'font-argent'}/>
        <Icon name={"arrow-down"} />
      </div>
    </PublicTalkWidgetStyle>
  );
};

export default PublicTalkWidget;
