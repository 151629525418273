import styled from 'styled-components';

const BurgerMenuStyle = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 100%;
	background-color: var(--backgroundColor);
	z-index: 10;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: var(--sp9x);
	overflow-y: auto;

	&.active {
		left: 0;
	}

	.menus-wrap {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		padding: var(--sp8x) 0 var(--sp12x) 0;

		.burger-menu-link {
			position: relative;
			margin-bottom: var(--sp3x);
			text-align: center;

			&::before {
				content: '';
				position: absolute;
				top: calc(100% + var(--sp2x));
				left: 0;
				width: 100%;
				height: 1px;
				background-color: transparent;
			}

			&.active {
				&::before {
					background-color: var(--royalBlue1000);
				}
			}
		}

		.contact {
			margin-bottom: var(--sp7x);
		}
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		margin-top: var(--sp7x);

		.menus-wrap {
			padding: var(--sp6x) 0 var(--sp10x) 0;
		}
	}
`;

export default BurgerMenuStyle;
