'use client';

import { useRef, useImperativeHandle, forwardRef } from 'react';

//* Style
import ContainerStyle from './style';

const Container = forwardRef(({ row, isSection, full, className, children, onMouseMove }, ref) => {
	//! Ref
	const contRef = useRef();

	//! Imperative Handle
	useImperativeHandle(ref, () => contRef.current, []);

	return (
		<ContainerStyle
			ref={contRef}
			onMouseMove={onMouseMove || null}
			className={`container ${className || ''} ${isSection ? 'section-distance' : ''} ${full ? 'full' : ''}`}>
			{row ? <div className='row'>{children}</div> : children}
		</ContainerStyle>
	);
});

Container.displayName = 'Container';

export default Container;
