import { useState } from 'react';

//* Style
import PopupSubscribePlanStyle from './style';

//* HOC's
import { withLanguageContext, withUIContext, withDataContext } from '@/context';

//* Components
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';
import Container from '@/components/global/Container';
import FormItem from '@/components/global/Form/FormItem';
import FormInput from '@/components/global/Form/FormInput';
import FormContainer from '@/components/global/Form/FormContainer';
import { useSearchParams } from 'next/navigation'

const PopupSubscribePlan = ({ price, name, translate, closePopup, planSubscribe, isSwitchActive, hasPricing = false }) => {
	//! State
	const [isSuccess, setIsSuccess] = useState(false);

    const searchParams = useSearchParams()
    const search = searchParams.toString()

    //! Handle Finish
    const handleFinish = (values) => {
        if (!isSuccess) {

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
			 'event': 'email',
			});

            return planSubscribe({
                plan: name || null,
                planType:isSwitchActive ? 'Year' : 'Month',
                ...values
            }).then(() => {
                setIsSuccess(true);

                setTimeout(() => {
                    setIsSuccess(false);
                    closePopup();
                }, 60000);
            });
        }
    };

	return (
		<PopupSubscribePlanStyle>
			<Text
				className={'h5 font-argent letter-spacing-h5 white-1000 subscribe-plan-title'}
				text={'joinTheWaitList'}
			/>
			{hasPricing && (
				<Text
					className={'p p6 font-avenir-regular white-1000'}
					text={`${name} ${isSwitchActive ? `(${Number(price.per_year).toLocaleString('ru')}$ / per year)` : `(${Number(price.per_month).toLocaleString('ru')}$ / per month)`}`}
				/>
			)}
			<FormContainer onFinish={handleFinish} initialValues={{ ["utm"]: search ? search : "" }} >
				<Container
					row
					full>
					<div className='col-6 col-m-12'>
						<FormItem name={'first_name'}>
							<FormInput placeholder={translate('firstNamePlaceholder')} />
						</FormItem>
					</div>
					<div className='col-6 col-m-12'>
						<FormItem name={'last_name'}>
							<FormInput placeholder={translate('lastNamePlaceholder')} />
						</FormItem>
					</div>
					<div className='col-12'>
						<FormItem
							validationType={'email'}
							name={'email'}>
							<FormInput placeholder={translate('emailPlaceholder')} />
						</FormItem>
						<FormItem
							name={'utm'}
							required={false}>
							<FormInput type="hidden" />
						</FormItem>
					</div>
					<div className='col-12'>
						{isSuccess ? (
							<Text
								className={'p p6 success-color success-message'}
								text={'successWaitListText'}
							/>
						) : (
							<Button
								type={'submit'}
								text={'submit'}
								className={'primary inverse subscribe-button'}
							/>
						)}
					</div>
				</Container>
			</FormContainer>
		</PopupSubscribePlanStyle>
	);
};

export default withDataContext(withUIContext(withLanguageContext(PopupSubscribePlan, ['translate']), ['closePopup']), ['planSubscribe']);
