import styled from 'styled-components';

const LanguageSwitcher = styled.div`
	.selected-lang-wrap {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		width: var(--sp6x);

		.language-dropdown-icon {
			margin-left: var(--sp1x);
			transition: 0.3s;

			&.active {
				transform: scaleY(-1);
			}
		}

		.dropdown {
			position: absolute;
			top: 200%;
			left: 50%;
			transform: translateX(-50%);
			background-color: var(--backgroundColor);
			border: 1px solid var(--royalBlue1000);
			border-radius: var(--sp0-5x);
			width: 100%;
			z-index: 10;

			.lang-item {
				text-align: center;
				transition: background-color var(--trTime) ease-out;
				padding: var(--sp1-5x) 0;

				@media (hover: hover) {
					&:hover {
						background-color: var(--royalBlue50);
					}
				}
			}
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
	}
`;

export default LanguageSwitcher;
