import styled from "styled-components";

export default styled.div`
  --z-notifications: 300;
  --color-notification-green100: #e6f5ec;
  --color-notification-green200: #019f3c;
  --color-notification-blue100: var(--sky100);
  --color-notification-blue200: var(--sky200);
  --color-notification-yellow100: #fef4e6;
  --color-notification-yellow200: #f29100;
  --color-notification-red100: #fbe8ea;
  --color-notification-red200: var(--flame200);
  --color-white: white;

  position: fixed;
  right: 24px;
  top: 24px;
  z-index: var(--z-notifications);
  gap: 6px;
  display: flex;
  flex-direction: column-reverse;

  .notification {
    --color: var(--color-notification-green200);
    --bg-color: var(--color-notification-green100);

    position: relative;
    right: 0;
    width: 375px;
    display: flex;
    border-radius: 12px;
    padding: 16px 16px 16px 12px;
    background-color: var(--color-white);
    align-items: center;
    border: 1px solid var(--color);
    animation: ease-out slideIn 0.25s forwards;

    @media screen and (max-width: 800px) {
      max-width: calc(100vw - 32px);
      position: fixed;
      right: 16px;
      animation: ease-out slideTopIn 0.25s forwards;
      display: none;
      &:last-of-type {
        display: flex;
      }
    }

    &.isClosing {
      animation: ease-out slideOut 0.25s forwards;
      @media screen and (max-width: 800px) {
        animation: ease-out slideTopOut 0.25s forwards;
      }
    }
    &::before {
      position: absolute;
      content: "";
      background-color: var(--bg-color);
      width: 44px;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      border-radius: 12px 0 0 12px;
    }

    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      width: 100%;
      padding-left: 16px;
      padding-right: 12px;
    }

    .iconWrapper {
      height: 20px;
    }

    .iconWrapper .icon {
      height: 20px;
      font-size: 21px;
      position: relative;
      color: var(--color);
    }

    .close {
      width: 24px;
      height: fit-content;
      cursor: pointer;
      padding: 4px;
      transition: background-color 0.2s;
      border-radius: 8px;
      &:hover {
        background-color: rgba(0, 0, 0, 0.03);
      }
    }
  }

  .notificationSuccess {
    --color: var(--color-notification-green200);
    --bg-color: var(--color-notification-green100);
  }

  .notificationInfo {
    --color: var(--color-notification-blue200);
    --bg-color: var(--color-notification-blue100);
  }

  .notificationWarning {
    --color: var(--color-notification-yellow200);
    --bg-color: var(--color-notification-yellow100);
  }

  .notificationError {
    --color: var(--color-notification-red200);
    --bg-color: var(--color-notification-red100);
  }

  @keyframes slideIn {
    0% {
      right: -400px;
      opacity: 0;
    }
    100% {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes slideOut {
    0% {
      right: 0px;
      opacity: 1;
    }
    100% {
      right: -400px;
      opacity: 0;
    }
  }

  @keyframes slideTopIn {
    0% {
      top: -100px;
      opacity: 0;
    }
    100% {
      top: 24px;
      opacity: 1;
    }
  }

  @keyframes slideTopOut {
    0% {
      top: 25px;
      opacity: 1;
    }
    100% {
      top: -100px;
      opacity: 0;
    }
  }
`;
